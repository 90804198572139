import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import styled from 'styled-components'

const StyledDivWhole = styled.div`
  @media (min-width: 1024px) {
    width: 160px;
    height: 190px;
  }

  @media (min-width: 1280px) {
    width: 200px;
    height: 226px;
  }

  @media (min-width: 1920px) {
    width: 478px;
    height: 226px;
  }
`

const StyledDiv = styled.div`
  width: 10rem;
  height: 0;
  position: absolute;
  top: 0px;
  margin-right: -40px;
  right: 0px;
  border-bottom: 40px solid #b02929;
  border-right: 40px solid transparent;

  @media (min-width: 1024px) {
    width: 5rem;
    height: 0;
    position: absolute;
    top: 0px;
    margin-right: -40px;
    right: 0px;
    border-bottom: 40px solid #b02929;
    border-right: 40px solid transparent;
  }

  @media (min-width: 1920px) {
    width: 10rem;
    height: 0;
    position: absolute;
    top: 0px;
    margin-right: -40px;
    right: 0px;
    border-bottom: 40px solid #b02929;
    border-right: 40px solid transparent;
  }
`

const StyledDivNew = styled.div`
  width: 13rem;
  height: 4rem;
  position: absolute;
  margin-top: -2rem;
  background-color: #b02929;
  border-top-left-radius: 1.5rem;
  border-bottom-left-radius: 1.5rem;

  @media (min-width: 1024px) {
    width: 6rem;
    height: 4rem;
    position: absolute;
    margin-top: -2rem;
    background-color: #b02929;
    border-top-left-radius: 1.5rem;
    border-bottom-left-radius: 1.5rem;
  }

  @media (min-width: 1920px) {
    width: 13rem;
    height: 4rem;
    position: absolute;
    margin-top: -2rem;
    background-color: #b02929;
    border-top-left-radius: 1.5rem;
    border-bottom-left-radius: 1.5rem;
  }
`

const PortalCardUpdate = ({ cardTitle, cardText, cardLink }) => {
  return (
    <>
      <Link to={cardLink ? cardLink : "/dashboard"}>
        <StyledDivWhole className="relative bg-red-900 rounded-r-3xl rounded-b-3xl rounded-l-none hover:shadow-outline-gray mb-12 md:mb-6 z-10">
          <StyledDivNew>
            <StyledDiv></StyledDiv>
          </StyledDivNew>
          <div className="relative px-8  pt-8 pb-12 lg:px-4 lg:pt-8 lg:pb-12 xl:px-8 3xl:pt-8 3xl:pb-12 z-30">
            <div>
              <h2 className="text-5xl lg:text-lg xl:text-xl 3xl:text-4xl font-extrabold tracking-tight text-red-100">
                <span className="block leading-snug tracking-wide">{cardTitle ? cardTitle : "Title"}</span>
              </h2>
              <p className="max-w-sm text-white text-xl lg:text-xs xl:text-sm 3xl:text-xl mt-6">{cardText ? cardText : null}</p>
            </div>
          </div>
        </StyledDivWhole>
      </Link>
    </>
  )
}

PortalCardUpdate.propTypes = {
  cardTitle: PropTypes.string,
 cardText: PropTypes.string,
  cardLink: PropTypes.string,
}

export default PortalCardUpdate