import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import PortalCardUpdate from '../Common/PortalCardUpdate'

const CardsDashboardUpdate = () => {
  const data = useStaticQuery(graphql`
    query CardsDashboardUpdateQuery {
      prismicSraSessions {
        data {
          title
          description
        }
      }
      prismicSraSymposiums {
        data {
          title
          description
        }
      }
      prismicSalesVideos {
        data {
          title
          description
        }
      }
      prismicSalesSupportDocuments {
        data {
          title
          description
        }
      }
      prismicNewFormationInformation {
        data {
          title
          description
        }
      }
      prismicTrainingVideos {
        data {
          title
          description
        }
      }
      prismicSampleDocuments {
        data {
          title
          description
        }
      }
      prismicYearlyRenewals {
        data {
          title
          description
        }
      }
    }
  `)

const docSraSessions = data.prismicSraSessions
const docSraSymposiums = data.prismicSraSymposiums
const docSalesVideos = data.prismicSalesVideos
  const docSalesSupportDocuments = data.prismicSalesSupportDocuments
  const docNewFormationInformation = data.prismicNewFormationInformation
  const docTrainingVideos = data.prismicTrainingVideos
  const docSampleDocuments = data.prismicSampleDocuments
  const docYearlyRenewals = data.prismicYearlyRenewals

  return (
    <div className='grid grid-cols-1 lg:grid-cols-4 3xl:grid-cols-3 gap-6 xl:gap-12 mt-0 md:mt-16 lg:mt-12 3xl:mt-16'>
      <PortalCardUpdate cardTitle={docSraSessions.data.title} cardText={docSraSessions.data.description} cardLink="/sra-sessions" />
      <PortalCardUpdate cardTitle={docSraSymposiums.data.title} cardText={docSraSymposiums.data.description} cardLink="/symposiums" />
      <PortalCardUpdate cardTitle={docSalesVideos.data.title} cardText={docSalesVideos.data.description} cardLink="/sales-marketing-videos" />
      <PortalCardUpdate cardTitle={docYearlyRenewals.data.title} cardText={docYearlyRenewals.data.description} cardLink="/renewal-documents" />
      <PortalCardUpdate cardTitle={docNewFormationInformation.data.title} cardText={docNewFormationInformation.data.description} cardLink="/new-formation-information" />
      <PortalCardUpdate cardTitle={docTrainingVideos.data.title} cardText={docTrainingVideos.data.description} cardLink="/training-videos" />
      <PortalCardUpdate cardTitle={docSalesSupportDocuments.data.title} cardText={docSalesSupportDocuments.data.description} cardLink="/sales-support-documents" />
      <PortalCardUpdate cardTitle={docSampleDocuments.data.title} cardText={docSampleDocuments.data.description} cardLink="/sample-documents" />
    </div>
  )
}

export default CardsDashboardUpdate