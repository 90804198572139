import React from 'react';
import { compose } from 'recompose';
import { useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import DashboardWrapper from '../components/Dashboard/DashboardWrapper';
import Layout from '../components/layout';
import CardsDashboardUpdate from '../components/Sections/CardsDashboardUpdate';
import {
  withAuthorization,
} from '../components/Session';
import HeadData from "../data/HeadData"

const DashboardPageBase = () => {
  const data = useStaticQuery(graphql`
    query DashboardPageBaseQuery {
      imgMtnBg: file(relativePath: { eq: "bg/bg-mountain-comp-1.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            width: 1920
            placeholder: TRACED_SVG
          )
        }
      }
    }
  `)

  const imgMtnBg = getImage(data.imgMtnBg)

  return (
    <DashboardWrapper isDashboardPage pageName='Dashboard'>
      <div className="relative" style={{ display: "grid" }}>
        <GatsbyImage
          style={{
            gridArea: "1/1",
            objectFit: "cover",
            width: "100vw",
            height: "100vh",
            position: "fixed",
          }}
          alt=""
          image={imgMtnBg}
        />
        <div
          style={{
            gridArea: "1/1",
            position: "relative",
            placeItems: "center",
            display: "grid",
          }}
        >
          <div className="relative md:pt-24 pb-32 pt-20 md:px-8 px-0 2xl:px-0">
            <div className="container mx-auto px-4 lg:px-0 xl:px-4 2xl:px-0">
              <CardsDashboardUpdate />
            </div>
          </div>
        </div>
      </div>
    </DashboardWrapper>
  )
};

const condition = authUser => !!authUser;

const DashboardPage = compose(
  withAuthorization(condition),
)(DashboardPageBase);

const Dashboard = () => (
  <Layout>
    <HeadData title='Dashboard' />
    <DashboardPage />
  </Layout>
)

export default Dashboard
